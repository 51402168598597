<template>
  <div class="dashboard">
    <el-card v-loading="loading" style="margin-bottom: 15px;">
      <el-row class="static-item">
        <el-col :span="6">
          <h3>新闻总数</h3>
          <p class="num">{{ news }}</p>
        </el-col>
        <el-col :span="6">
          <h3>合作伙伴</h3>
          <p class="num">{{ partner }}</p>
        </el-col>
        <el-col :span="6">
          <h3>在招职位</h3>
          <p class="num">{{ jobs }}</p>
        </el-col>
        <el-col :span="6">
          <h3>接收简历</h3>
          <p class="num">{{ resume }}</p>
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="15" style="margin-top: 15px;">
      <el-col :span="12">
        <el-card>
          <div slot="header">
            最近的留言
            <router-link to="/comments" style="float:right;">查看更多</router-link>
          </div>
          <el-table :data="comments" v-loading="commentsLoading">
            <el-table-column label="姓名" prop="name" width="120px"></el-table-column>
            <el-table-column label="留言内容" prop="message"></el-table-column>
            <el-table-column label="时间" prop="created_at" width="200px"></el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header">
            最近收到的简历
            <router-link to="/resume" style="float:right;">查看更多</router-link>
          </div>
          <el-table :data="resumeList" size="mini" v-loading="resumeLoading">
            <el-table-column label="申请职位" prop="jobs.name"></el-table-column>
            <el-table-column label="应聘者姓名" prop="name"></el-table-column>
            <el-table-column label="时间" prop="created_at"></el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <a :href="scope.row.attachment_url" target="_blank">
                  <el-button type="primary" size="mini">查看附件</el-button>
                </a>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss">
  .dashboard {
    .static-item {
      text-align: center;
    }
    .static-item h3 {
      font-size: 14px;
      font-weight: normal;
      color: #909399;
    }
    .num {
      font-size: 45px;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
</style>

<script>
import { indexStatistic } from '@/request/modules/dashboard'
import { commentsList } from '@/request/modules/comments'
import { resumeList } from '@/request/modules/resume'

export default {
  data () {
    return {
      news: 0,
      partner: 0,
      jobs: 0,
      resume: 0,
      loading: false,
      commentsLoading: false,
      comments: [],
      resumeList: [],
      resumeLoading: false
    }
  },
  methods: {
    getData () {
      this.loading = true
      indexStatistic({}).then(resp => {
        this.news = resp.news
        Object.keys(this).map(key => {
          if (resp[key]) {
            this[key] = resp[key]
          }
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    fetchComments () {
      this.commentsLoading = true
      commentsList({ keyword: '', page: 1 }).then(resp => {
        this.comments = resp.list
        this.commentsLoading = false
      }).catch(() => {
        this.commentsLoading = false
      })
    },
    fetchResume () {
      this.resumeLoading = true
      resumeList({ keyword: '', page: 1 }).then(resp => {
        this.resumeList = resp.list
        this.resumeLoading = false
      }).catch(() => {
        this.resumeLoading = false
      })
    }
  },
  mounted () {
    this.getData()
    this.fetchComments()
    this.fetchResume()
  }
}
</script>
