import request from '@/request'
/**
 * 首页统计信息
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const indexStatistic = (params) => {
  return request.get('/index', {
    params
  })
}
